import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';

export enum BreakpointPrefix {
  XS = 'xs', // Extra small devices (portrait phones)
  SM = 'sm', // Small devices (landscape phones)
  MD = 'md', // Medium devices (tablets)
  LG = 'lg', // Large devices (desktops)
  XL = 'xl', // Extra large devices (large desktops)
  XXL = 'xxl', // Extra extra large devices (larger desktops)
}

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService {
  private screenSizeSubject = new BehaviorSubject<number>(window.innerWidth);
  private screenWidth = window.innerWidth;

  constructor(private ngZone: NgZone) {
    this.ngZone.runOutsideAngular(() => {
      fromEvent(window, 'resize')
        .pipe(
          debounceTime(200),
          map((event) => (event.target as Window).innerWidth),
          distinctUntilChanged(),
        )
        .subscribe((width: number) => {
          this.ngZone.run(() => {
            this.screenSizeSubject.next(width);
            this.screenWidth = width;
          });
        });
    });
  }

  get screenSize$(): Observable<number> {
    return this.screenSizeSubject.asObservable();
  }

  get isMobile$(): Observable<boolean> {
    return this.screenSizeSubject.pipe(
      map((screenSize) => this.isScreenBelow(BreakpointPrefix.SM)),
      distinctUntilChanged(),
    );
  }

  isMobile(): boolean {
    return this.isScreenBelow(BreakpointPrefix.SM);
  }

  showPagination(): boolean {
    return this.isScreenAbove(BreakpointPrefix.SM);
  }

  isScreenBelow(breakpoint: BreakpointPrefix): boolean {
    return !this.isScreenAbove(breakpoint);
  }

  isScreenAbove(breakpoint: BreakpointPrefix): boolean {
    const breakpoints = {
      [BreakpointPrefix.XS]: 0, // Extra small devices
      [BreakpointPrefix.SM]: 576, // Small devices
      [BreakpointPrefix.MD]: 768, // Medium devices
      [BreakpointPrefix.LG]: 992, // Large devices
      [BreakpointPrefix.XL]: 1200, // Extra large devices
      [BreakpointPrefix.XXL]: 1600, // Extra extra large devices
    };

    const screenWidth = window.innerWidth; //size was not detected correctly when resizing

    // Determine the next higher breakpoint's minimum width
    const breakpointValues = Object.values(breakpoints);
    const currentBreakpointIndex = breakpointValues.indexOf(breakpoints[breakpoint]);
    const nextBreakpointValue = breakpointValues[currentBreakpointIndex + 1] || Infinity;

    return screenWidth >= nextBreakpointValue;
  }
}
