export enum CustomHttpHeaderName {
  REQUEST_SCOPE = 'x-request-scope',
  CLIENT_TYPE = 'x-client-type',
  REQUEST_ID = 'x-request-id',
  IGNORE_LOADING = 'x-ignore-loading',
}

export enum HttpRequestScope {
  REFRESH_TOKEN = 'refresh-token',
  LOGOUT = 'logout',
  CHECK_AUTH_STATUS = 'check-auth-status',
}
