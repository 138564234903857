import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageKey, WindowService } from '@services/window.service';
import { environment } from '@env/environment';
import { CustomHttpHeaderName } from 'src/app/types/http.types';
import { v4 as uuidv4 } from 'uuid';
import { LoadingService } from 'src/app/shared/components/loading/loading.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(
    private windowService: WindowService,
    private loadingService: LoadingService,
  ) {}

  intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
    // it is recommended to use the 'clone' method when changing the request object
    const requestHeaders = this.getHeaders(request);
    const clonedRequest = request.clone({
      // set HTTP headers to be applied on request
      setHeaders: requestHeaders,
      // normalize HTTP request's URL
      url: this.normalizeUrl(request.url),
    });

    // update the global loading state
    const skipLoading = request.headers.has(CustomHttpHeaderName.IGNORE_LOADING);
    if (!skipLoading) {
      const requestId = requestHeaders[CustomHttpHeaderName.REQUEST_ID];
      if (requestId) {
        this.loadingService.startAction(requestId);
      }
    }

    return next.handle(clonedRequest);
  }

  /**
   * GetHTTP headers to be set on given HTTP request
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getHeaders(request: HttpRequest<any>) {
    const headers: Record<string, string> = {};

    const contentTypeHeader = request.headers.get('Content-Type');
    const contentDispositionHeader = request.headers.get('Content-Disposition');
    const authorizationHeader = request.headers.get('Authorization');

    // set Content-Type if not present
    if (
      !contentTypeHeader &&
      // let the browser set the Content-Type header for file uploads
      !contentDispositionHeader &&
      // do not set content type on DELETE requests (because JSON expects a JSON body)
      request.method !== 'DELETE'
    ) {
      headers['Content-Type'] = 'application/json';
    }

    // set Auth Header if not already set
    if (!authorizationHeader) {
      const accessToken = this.windowService.getStorage(StorageKey.AccessToken);
      if (accessToken) {
        headers['Authorization'] = `Bearer ${accessToken}`;
      }
    }

    // set the Client Type header
    headers[CustomHttpHeaderName.CLIENT_TYPE] = 'web';
    // set the Request ID header
    headers[CustomHttpHeaderName.REQUEST_ID] = uuidv4();

    return headers;
  }

  /**
   * Set API prefix if necessary
   */
  private normalizeUrl(url: string): string {
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url;
    } else {
      return `${environment.apiUrl}${url}`;
    }
  }
}
