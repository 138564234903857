import { Injectable } from '@angular/core';
import {
  AuthUser,
  ForgotPasswordPayload,
  LoginPayload,
  LoginResponse,
  RegisterPayload,
  ResetPasswordPayload,
  SocialRegisterPayload,
  VerifyEmailPayload,
} from '@services/api/auth-api.types';
import { HttpClient } from '@angular/common/http';
import { CustomHttpHeaderName, HttpRequestScope } from 'src/app/types/http.types';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  constructor(private readonly http: HttpClient) {}

  register(data: RegisterPayload) {
    return this.http.post<LoginResponse>('auth/signup', data, {
      headers: {
        [CustomHttpHeaderName.IGNORE_LOADING]: 'true',
      },
    });
  }

  login(data: LoginPayload) {
    return this.http.post<LoginResponse>('auth/signin', data);
  }

  loginWithGoogle(authToken: string) {
    return this.http.post<LoginResponse>('auth/social/google/signin', { authToken });
  }

  registerWithGoogle(data: SocialRegisterPayload) {
    return this.http.post<LoginResponse>('auth/social/google/signup', data, {
      headers: {
        [CustomHttpHeaderName.IGNORE_LOADING]: 'true',
      },
    });
  }

  loginWithFacebook(authToken: string) {
    return this.http.post<LoginResponse>('auth/social/facebook/signin', { authToken });
  }

  registerWithFacebook(data: SocialRegisterPayload) {
    return this.http.post<LoginResponse>('auth/social/facebook/signup', data, {
      headers: {
        [CustomHttpHeaderName.IGNORE_LOADING]: 'true',
      },
    });
  }

  refreshToken(refreshToken: string) {
    return this.http.post<LoginResponse>(
      'auth/refresh-token',
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
          [CustomHttpHeaderName.REQUEST_SCOPE]: HttpRequestScope.REFRESH_TOKEN,
        },
      },
    );
  }

  logout(refreshToken: string, fromAllDevices: boolean = false) {
    return this.http.post(
      'auth/logout',
      { fromAllDevices },
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
          [CustomHttpHeaderName.REQUEST_SCOPE]: HttpRequestScope.LOGOUT,
        },
      },
    );
  }

  getProfile(checkAuthStatus: boolean = false) {
    const headers = checkAuthStatus ? { [CustomHttpHeaderName.REQUEST_SCOPE]: HttpRequestScope.CHECK_AUTH_STATUS } : {};
    return this.http.get<AuthUser>('auth/profile', { headers });
  }

  forgotPassword(data: ForgotPasswordPayload) {
    return this.http.post<void>('auth/forgot-password', data);
  }

  resetPassword(data: ResetPasswordPayload) {
    return this.http.post<void>('auth/reset-password', data);
  }

  verifyEmail(data: VerifyEmailPayload) {
    return this.http.post<void>('auth/verify-email', data);
  }
}
